import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input";

// Connects to data-controller="applicant"
export default class extends Controller {
  connect() {
    const input = document.querySelector("#applicant_phone_number");

    intlTelInput(input, {
      separateDialCode: true,
      strictMode: true,
      initialCountry: "auto",
      geoIpLookup: (callback) => {
        fetch("https://ipapi.co/json")
          .then((res) => res.json())
          .then((data) => callback(data.country_code))
          .catch(() => callback("us"));
      },
      loadUtilsOnInit: () => import("intl-tel-input/utils"),
      hiddenInput: function (telInputName) {
        return {
          phone: "applicant[phone_number]",
          country: "applicant[phone_country]",
        };
      },
    });
  }
}
